<template>
	<section>
		<div class="profile-section">
			<div class="profile-sub-section" v-if="isOwner">
				<div class="profile-sub-section-left">
					<AppIcon class="color-dark" src="settings" />
				</div>
				<div class="profile-sub-section-right">
					<div class="profile-sub-secion-right-heading">
						<div class="h3 text-bold h-full">General</div>
					</div>
					<div class="profile-sub-secion-right-name w-full">
						<AppInput
							v-model="community_name"
							class="form-input"
							label="Community Name"
							:maxlength="100"
							:error="v$.community_name.$errors"
						/>
					</div>
				</div>
			</div>
			<div class="profile-sub-section">
				<div class="profile-sub-section-left">
					<AppIcon class="color-dark" src="person" />
				</div>
				<div class="profile-sub-section-right">
					<div class="profile-sub-secion-right-heading">
						<div class="h3 text-bold h-full">Profile</div>
					</div>
					<div class="profile-sub-secion-right-image">
						<div class="profile-photo-wrapper mb-3" id="pick-avatar">
							<a class="cursor-pointer" @click="uploadProfilePhoto">
								<AppUserImage :src="profile_image" :name="full_name" :color="color" class="lg" />

								<div class="abs-add-image">
									<img src="@/assets/icons/add-image-filled.svg?url" class="img-fluid" />
								</div>
							</a>
							<AppUploadImage ref="uploadProfilePhoto" @uploaded="data => fileUploaded(data)" scope="user"> </AppUploadImage>
						</div>
					</div>
					<div class="profile-sub-secion-right-name w-full">
						<AppInput v-model="full_name" class="form-input" label="Your Display Name" :maxlength="100" :error="v$.full_name.$errors" />
					</div>
				</div>
			</div>
			<div class="profile-sub-section" v-if="isOwner">
				<div class="profile-sub-section-left">
					<AppIcon class="color-dark" src="person" />
				</div>
				<div class="profile-sub-section-right">
					<div class="profile-sub-secion-right-heading">
						<div class="h3 text-bold h-full">Intro Message</div>
						<div class="para-2 text-light h-full">Your audience will see this message when they join your Community.</div>
					</div>
					<div class="profile-sub-secion-right-name w-full flex gap-6 relative lg:flex-nowrap flex-wrap lg:pr-0 pr-8">
						<div
							class="w-full p-2 bg-white flex shadow-stan-box-shadow flex-col lg:max-w-96 min-h-80 max-w-full rounded-xl border-0 overflow-hidden tip-tap"
						>
							<TipTapEditor
								v-model:value="content"
								hasToolbar
								:should-show-link="false"
								toolbar-class="!h-8 !mt-0"
								editor-wrapper-class="grow"
								content-type="html"
								placeholder="Write Something Here..."
								:shouldShowLink="false"
							></TipTapEditor>
						</div>
						<div class="select-none flex w-full lg:max-w-96 max-w-full max-h-96 px-3 py-6 rounded-xl bg-[--stan-lavender-pink-color]">
							<div class="shadow-box-shadow-lg rounded-xl bg-white p-4 w-full">
								<div class="mb-5 relative">
									<div class="close-container editor-close">
										<AppIcon src="close" class="close" />
									</div>
									<div class="shadow-stan-box-inner-custom h-12 w-32 mx-auto">
										<div
											class="p-1 shadow-stan-image-shadow bg-white mt-2.5 rounded-full flex justify-center items-center max-w-10 min-h-10 mx-auto"
										>
											<img class="rounded-full min-h-8" :src="profile_image" width="33" height="33" alt="Profile Picture" />
										</div>
									</div>
								</div>
								<div class="h-60 shadow-stan-box-inner-custom overflow-auto bg-white p-4" v-html="content"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="profile-sub-section">
				<div class="profile-sub-section-left">
					<AppIcon class="color-dark" src="mail" />
				</div>
				<div class="profile-sub-section-right">
					<div class="profile-sub-secion-right-heading">
						<div class="h3 text-bold h-full">Notifications</div>
						<div class="para-2 text-light h-full">Select how often you want to receive notification emails from this Community.</div>
					</div>
					<div class="profile-sub-secion-right-name w-full">
						<AppRadioButton
							v-model="notification_frequency"
							:choices="[
								{ label: 'Daily', value: 'daily' },
								{ label: 'Weekly', value: 'weekly' },
								{ label: 'Never', value: 'never' },
							]"
						/>
					</div>
				</div>
			</div>
			<Transition name="fade">
				<PaymentsSection v-if="showPayments" />
			</Transition>

			<div class="profile-sub-section">
				<div class="w-full mt-4">
					<AppButton :disabled="!canSubmit" :loading="formSubmitting" @click="saveProfile" class="w-full sm:w-auto">Save</AppButton>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from 'axios'
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'
	import { useAuthStore } from '@/stores/auth'
	import { useMembershipStore } from '@/stores/membership'
	import TipTapEditor from '../shared/TipTapEditor.vue'
	import AppIcon from '../shared/AppIcon.vue'

	export default {
		components: { TipTapEditor },
		setup() {
			return { v$: useVuelidate() }
		},
		data() {
			return {
				full_name: '',
				profile_image: '',
				color: '',
				community_name: '',
				notification_frequency: 'daily', // daily, weekly, never
				formSubmitting: false,
				cancelSubscriptionConfirmationModalLoading: false,
				content: ``,
				toolbarOptions: ['bold', 'italic', { list: 'bullet' }],
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			membershipStore() {
				return useMembershipStore()
			},
			showPayments() {
				return this.membershipStore.status != null
			},
			currentCommunity() {
				return this.communityStore.currentCommunity
			},
			canSubmit() {
				// Highlights fields on the fly
				this.v$.$validate()

				if (this.full_name && this.communityStore.self.data.full_name !== this.full_name) {
					return true
				}

				if (this.profile_image && this.communityStore.self.data.profile_image !== this.profile_image) {
					return true
				}

				if (
					this.notification_frequency &&
					this.communityStore.self.data?.notification?.notification_frequency !== this.notification_frequency
				) {
					return true
				}

				if (this.community_name && this.currentCommunity.data.name !== this.community_name) {
					return true
				}

				if (this.content) {
					return true
				}

				return false
			},
			self() {
				return this.communityStore.self
			},
			authStore() {
				return useAuthStore()
			},
			isCreator() {
				return !this.authStore.user
			},
			isOwner() {
				return this.communityStore.isOwner
			},
		},
		validations() {
			return {
				full_name: {
					required: helpers.withMessage('Enter a valid name', required),
				},
				community_name: {
					required: helpers.withMessage('Enter a valid name', required),
				},
			}
		},
		methods: {
			uploadProfilePhoto() {
				this.$refs.uploadProfilePhoto.triggerPickfile()
			},
			fileUploaded(fileData) {
				this.profile_image = fileData.src
				this.$refs.uploadProfilePhoto.hide()
			},
			async saveProfile() {
				this.formSubmitting = true
				const isFormCorrect = await this.v$.$validate()

				if (isFormCorrect) {
					await axios.put(`v1/communities/${this.communityStore.currentCommunity.slug}/self`, {
						full_name: this.full_name,
						profile_image: this.profile_image,
						notification: {
							notification_frequency: this.notification_frequency,
						},
					})
					this.communityStore.getSelf()
					this.communityStore.getMembers()

					if (this.isOwner) {
						// Update Community Owner data
						await this.communityStore.updateCommunityData({
							introduction: this.content,
							name: this.community_name,
						})

						this.$nextTick(async () => {
							await this.communityStore.setCurrentCommunity(this.communityStore.currentCommunity.slug)
						})
					}
					this.$emit('updated')
				}
				this.formSubmitting = false
			},
		},
		watch: {
			self: {
				immediate: true,
				handler(newVal) {
					if (newVal && newVal.data) {
						this.full_name = `${newVal?.data?.full_name}`
						this.color = `${newVal?.data?.color}`
						this.profile_image = newVal?.data?.profile_image ? `${newVal?.data?.profile_image}` : ''
						this.notification_frequency = newVal?.data?.notification?.notification_frequency
							? `${newVal?.data?.notification?.notification_frequency}`
							: 'daily'
					}
				},
			},
			currentCommunity: {
				immediate: true,
				handler(newVal) {
					if (newVal && newVal.data) {
						this.community_name = `${newVal?.data?.name}`
						this.content = newVal?.data?.introduction || ''
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.profile-section {
		@apply flex w-full md:p-5 flex-col items-start gap-10 sm:gap-5;
		.profile-sub-section {
			display: flex;
			align-items: flex-start;
			gap: 12px;
			align-self: stretch;
			&-left {
				display: flex;
				width: 22px;
			}
			&-right {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
				flex: 1 0 0;
			}
			.profile-sub-secion-right-heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
			}
			.profile-sub-secion-right-image {
				max-width: 100px;
				.profile-photo-wrapper {
					position: relative;
					.abs-add-image {
						position: absolute;
						right: -7px;
						bottom: -8px;
						display: inline-block;
						img {
							width: 20px;
						}
					}
				}
			}
		}
	}
	.tip-tap {
		:deep(.editor) {
			@apply max-h-80 overflow-auto h-full;
			box-shadow: var(--stan-box-shadow);
			.tiptap {
				@apply h-full;
			}
		}
	}

	.close-container {
		@apply flex w-full justify-end absolute right-3 top-3;

		.close {
			@apply flex w-6 h-6 bg-stan-gray-light rounded-full justify-center items-center cursor-pointer p-1;
		}
		&.editor-close {
			@apply flex w-full justify-end absolute right-0 top-0;
		}
	}
</style>
