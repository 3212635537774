/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const useProductsStore = defineStore('products', {
	state: () => ({
		_products: {},
		_loading: false,
		_current_community_slug: null,
	}),
	getters: {
		products: state => state._products[state._current_community_slug] || [],
		loading: state => state._loading,
		currentCommunitySlug: state => state._current_community_slug,
		webinars: state => state._products[state._current_community_slug]?._webinars || {},
		selectedWebinars: state => state._products[state._current_community_slug]?.selectedWebinars || [],
	},
	actions: {
		getProducts() {
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/products`)
					.then(response => {
						this._products[this._current_community_slug] = response.data.products
						resolve(this._products)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
					.finally(() => {
						this._loading = false
					})
			})
		},
		async setCurrentCommunity(communitySlug) {
			this._current_community_slug = communitySlug
			// Tries to get community data if don't already have it
			if (!this._products[communitySlug]) {
				await this.getProducts()
				await this.getWebinars()
			}
		},
		async getPreAuthCourseLink(courseId) {
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${this._current_community_slug}/course/${courseId}/link`)
					.then(response => {
						resolve(response.data?.link)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		getWebinars(){
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/webinars`)
					.then(response => {
						this._products[this._current_community_slug]._webinars = response.data.webinars
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
					.finally(() => {
						this._loading = false
					})
			})
		},
	},
})
