import axios from 'axios'

const configSettings = {
	siteName: 'Stan',
	env_name: import.meta.env.VITE_APP_ENV_NAME,
	communityApiUrl: import.meta.env.VITE_APP_API_COMMUNITY_BASE_URL,
	authApiUrl: import.meta.env.VITE_APP_API_AUTH_BASE_URL,
	noHttpsDomain: import.meta.env.VITE_FRONT_DOMAIN_NO_PROTOCOL,
	frontDomain: `https://${import.meta.env.VITE_FRONT_DOMAIN_NO_PROTOCOL}`,
	noHttpsLandingDomain: import.meta.env.VITE_LANDING_DOMAIN_NO_PROTOCOL,
	landingDomain: `https://${import.meta.env.VITE_LANDING_DOMAIN_NO_PROTOCOL}`,
	noHttpsAdminDomain: import.meta.env.VITE_ADMIN_DOMAIN_NO_PROTOCOL,
	adminDomain: `https://${import.meta.env.VITE_ADMIN_DOMAIN_NO_PROTOCOL}`,
	analyticsApi: import.meta.env.VITE_APP_ANALYTICS_API,
	sentryDsn: import.meta.env.VITE_APP_SENTRY_DSN,
	unsplashApiClientId: import.meta.env.VITE_APP_UNSPLASH_API_CLIENT_ID || null,
	releaseVersion: import.meta.env.VITE_ENV_RELEASE_VERSION,
	assetsDomain: import.meta.env.VITE_APP_IMAGE_ASSETS,
	clarityTrackingCode: import.meta.env.VITE_APP_CLARITY_TRACKING_CODE,
	sseStreamApiUrl: import.meta.env.VITE_APP_API_SSE_STREAM_URL,
}

axios.defaults.baseURL = configSettings.communityApiUrl

export { configSettings }
