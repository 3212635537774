<template>
	<AppModal ref="deleteCategoryModal" title="Confirm Deletion"
		:subheading='`Deleting category "${this.tagName}" will unlink categorized posts, but they will not delete them from your community.`'
		action="Delete" action-color="danger" :loading="loading" secondary-action="Cancel" secondary-action-color="danger" @cta="deleteTag"
		@secondaryCta="close" @close="close">
	</AppModal>
</template>

<script>
import { useCommunityStore } from '@/stores/communities'

export default {
	props: {
		tagId: {
			type: String,
			default: ''
		},
		tagName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		communityStore() {
			return useCommunityStore()
		},
	},

	methods: {
		show() {
			this.$refs.deleteCategoryModal.show()
		},
		close() {
			this.$emit('close')
			this.$refs.deleteCategoryModal.hide()
		},
		async deleteTag() {
			this.loading = true
			await this.communityStore.deleteTag(this.tagId)
			this.loading = false
			this.close()
		}
	},
}
</script>
