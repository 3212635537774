<template>
    <div v-for="(session, index) in upcomingSessions" :key="index">
        <div
		class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-white mb-4 hover:cursor-pointer p-4 px-3 gap-3;"
        @click="openWebinarPage"
	>
		<div class="flex flex-row w-full justify-between rounded-xl text-stan-text-dark text-sm">
			<div class="flex flex-row gap-2 w-full items-center">
				<img :src="webinar.data.product.image" class="helpful-webinar-image" />
				<div class="overflow-hidden">
					<div class="helpful-webinar-title para-2 text-bold">{{ webinar.data.product.title }}</div>
					<div class="helpful-webinar-url text-ellipsis overflow-x-hidden whitespace-nowrap overflow-y-visible">
						{{ formatDate(session.date) }} ● {{ convertTimeRange(session.time, webinar.data.product.webinar.duration_minutes) }}
					</div>
				</div>
			</div>
		</div>
	</div>
    </div>
</template>


<script setup>
	import { configSettings } from '@/plugins/configSettings';
	import { useCommunityPublicStore } from '@/stores/public';
	import { DateTime } from 'luxon'
    import { computed, defineProps } from 'vue';
	const communityPublicStore = useCommunityPublicStore()

    const props = defineProps({
		webinar: {
			type: Object,
			required: true,
		},
	});

	// Filter upcoming sessions
	const upcomingSessions = computed(() => {
		const now = new Date();
		return props.webinar.data.product.webinar.sessions.filter(session => {
			const sessionDate = new Date(`${session.date} ${session.time}`);
			return sessionDate > now;
		});
	});

    const openWebinarPage = () => {
        window.open(`${configSettings.frontDomain}/${communityPublicStore.username}/p/${props.webinar.slug}`,'blank');
    };

    function convertTimeRange(startTimeStr, duration) {
        const [time, modifier] = startTimeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const startDate = new Date();
        startDate.setHours(hours);
        startDate.setMinutes(minutes);

        const endDate = new Date(startDate.getTime() + duration * 60000);

		function formatTime(date) {
			const time = DateTime.fromJSDate(date);
			return time.toFormat('h:mm a').replace(':00', '');
		}
        const startFormatted = formatTime(startDate);
        const endFormatted = formatTime(endDate);

        return `${startFormatted} - ${endFormatted}`;
    }

	function formatDate(dateStr) {
    	const date = DateTime.fromISO(dateStr); 
    	return date.toFormat('MMM d, yyyy'); 
	}
</script>


<style lang="scss" scoped>
	.helpful-webinar- {
		&wrapper {
			@apply flex flex-row items-center justify-between shadow-stan-box-shadow overflow-hidden rounded-xl bg-white mb-4 hover:cursor-pointer p-4 gap-3;
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col max-w-[50%];
		}
		&title {
			@apply capitalize break-words break-all line-clamp-2 overflow-hidden text-ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.webinar {
			@apply ml-auto mr-1 p-2 hover:bg-stan-gray-light;
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.buttons-wrapper {
		@apply ml-auto flex flex-row gap-1 items-center;
	}
	.helpful-webinar-button {
		@apply rounded-md hover:bg-stan-gray-light p-1;
	}
	.dropdown-button {
		cursor: pointer;
		@apply bg-white rounded-full mt-2 mr-2;
	}

	.dropdown-menu {
		cursor: pointer;
		position: absolute;
		right: 32px;
		bottom: -80px;
		z-index: 1000;
		background: white;
		box-shadow: var(--stan-box-shadow);
	}

	.dropdown-menu ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.dropdown-menu li {
		margin: 8px;
		padding: 10px 10px;
		display: flex;
	}

	.dropdown-menu li:hover {
		transition: ease 0.2s;
		background-color: var(--stan-gray-light-color);
	}

	.dropdown-menu li a {
		text-decoration: none;
		color: var(--stan-black-1);
	}

	.dropdown-menu li a:hover {
		background: var(--stan-white-3);
	}

	.delete-dropdown {
		min-width: 150px;
	}
</style>
