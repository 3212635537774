<template>
	<div
		class="chat-message-section on-drop"
		:class="{ disable: disable, visible: visible }"
		:key="tick"
		ref="messageFormeditor"
		@dragover.prevent="handleDragOver"
		@dragenter.prevent
		@drop="handleDropOver"
	>
		<form class="chat-message-form para-2" @submit.prevent="sendMessage">
			<TipTapEditor
				v-model:value="content"
				:placeholder="placeholder"
				ref="tiptapEditor"
				hasToolbar
				:shouldShowImagePicker="!messageEditMode"
				:shouldShowFilePicker="!messageEditMode"
				editor-wrapper-class="content-wrapper"
				@pick-file="pickFile"
			>
				<template #pre-editor>
					<AppUserImage :name="user?.name" :src="user?.src" :color="user?.color" size="md" />
				</template>
				<template #toolbar-extra-tools>
					<AppAudioRecorder
						v-if="isOwner"
						ref="audioRecorderRef"
						scope="message"
						:messageEditMode="messageEditMode"
						@audio-file-ready="onAudioReady"
						@audio-file-progress="onAudioFileProgress"
						@audio-file-uploaded="onAudioFileUploaded"
						@audio-file-error="onAudioFileError"
					/>
				</template>
			</TipTapEditor>

			<!-- Attachments -->
			<transition name="fade">
				<div
					class="chat-message-attachment"
					key="attachment"
					v-show="!messageEditMode && (audioRecords.length || files.length || previewUrl.length > 0)"
				>
					<!-- Audio -->

					<div class="attachment audio" v-for="(record, index) in audioRecords" :key="`audio-record-${index}`">
						<div class="fileUploaded">
							<AppAudioPlayer
								:name="record.name"
								:time="record.time"
								:blob="record.blob"
								:src="record.src"
								:stop="record.stop"
								:options="record.options"
								@start-playing="ev => onAudioStartPlaying(index, ev)"
							></AppAudioPlayer>
							<div class="action-item d-none cursor-pointer" @click="onRemoveAudioRecord(index)">
								<AppIcon src="close" />
							</div>
						</div>
					</div>

					<!-- URLs -->
					<div v-for="(preview, index) in previewUrl" :key="`preview-${index}`">
						<div class="attachment">
							<div class="fileUploaded">
								<LinkPreview mode="edit" :preview="preview" />
								<div class="action-item d-none" @click="removePreviewUrl(preview['$key'])">
									<AppIcon src="close" />
								</div>
							</div>
						</div>
					</div>

					<!-- Images / Video / Files-->
					<div
						:key="index"
						v-for="(file, index) in files"
						class="attachment"
						:class="[digitalAssetType(file), { fileUploading: file.progress !== undefined, fileUploaded: file.progress === undefined }]"
					>
						<div class="w-full h-full overflow-hidden">
							<AppImage
								class="rounded img cursor-pointer"
								cover
								:previewImage="true"
								:src="file.url"
								:alt="file.name"
								v-if="digitalAssetType(file) === 'image'"
							/>
							<AppIcon src="arc" class="loading-icon animate-spin" />

							<div class="img" v-if="digitalAssetType(file) === 'file'">
								<AppIcon class="lg" src="file" />
							</div>
							<div class="img" v-if="digitalAssetType(file) === 'video'">
								<AppIcon class="sm" src="play" />
							</div>
						</div>
						<div class="file-details" v-if="digitalAssetType(file) === 'file' || digitalAssetType(file) === 'video'">
							<div class="file-name">
								{{ file.name }}
							</div>
							<div class="file-type">
								{{ digitalAssetTypeName(file) }}
							</div>
						</div>
						<div class="action-item d-none" @click="removeFile(file.id)">
							<AppIcon src="close" />
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div key="send" class="send-wrapper">
					<div class="edit-mode-buttons" v-if="messageEditMode">
						<AppButton outline color="primary" class="action-button" @click="cancel"> Cancel </AppButton>
						<AppButton color="primary" class="action-button" @click="sendMessage" v-if="messageEditMode"> Save </AppButton>
					</div>
					<div class="create-mode-buttons" v-else>
						<label v-if="isOwner" class="email-checkbox" for="send-email-notif">
							<AppCheckbox style="margin-top: 1px" toggle-id="send-email-notif" v-model="sendEmail" />
							Send an email notification
						</label>
						<label v-else></label>
						<div class="flex flex-row gap-2 items-center">
							<AppSelectDropdown
								v-model="selectedTag"
								class="w-28 max-w-28 sm:w-40 sm:max-w-40 lg:w-48 lg:max-w-48"
								dropdown-menu-class="overflow-auto max-h-[20vh]"
								:full-width="true"
								:options="tagOptions"
								:searchable="true"
							/>

							<AppButton
								size="sm"
								color="primary"
								class="action-button !w-fit"
								ref="sendMessage"
								:class="{ disabled: !canSubmit }"
								:loading="loading"
								@click="sendMessage"
								icon="send"
							>
								Create Post
							</AppButton>
						</div>
					</div>
				</div>
			</transition>
		</form>

		<AppFileUpload
			v-if="!messageEditMode && visible"
			ref="hiddenFileUploadMessage"
			:accepts="inputAccepts"
			:multiple="true"
			scope="message"
			:maxFilesAllowed="maxFilesAllowed"
			@assetUp="fileUploaded"
			@filePicked="filePicked"
			@progress="progressUploaded"
			@errorUp="errorFileUploaded"
		/>
		<div class="drag-n-drop-section" v-if="isDraggedFile" :class="{ 'on-drag': isDraggedFile }">Drag and Drop File Here</div>
	</div>
</template>

<!-- eslint-disable no-underscore-dangle -->
<script>
	import axios from 'axios'
	import { useCommunityStore } from '@/stores/communities'
	import { extractMentions } from '@/components/utils/tiptapEditorHelper'

	const DISABLE_LINK_PREVIEW = true

	export default {
		components: ['AppAudioRecorder'],
		props: {
			placeholder: {
				type: String,
				default: 'Write your message',
			},
			messageEditMode: {
				type: Boolean,
				default: false,
			},
			defaultContent: {
				type: String,
				default: '',
			},
			disable: {
				type: Boolean,
				default: false,
			},
			focus: {
				type: Boolean,
				default: true,
			},
			mentionGroup: {
				type: Boolean,
				default: true,
			},
			loadedFiles: {
				type: Array,
				default: () => [],
			},
			visible: {
				type: Boolean,
				default: true,
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				emojiOpened: false,
				content: '',
				contentUrls: new Map(),
				contentUrlsTimeout: null,
				inputAccepts: '*',
				files: [],
				id: `${Date.now()}`,
				audioRecords: [],
				audioRecorderDlgSelected: false,
				tick: 0,
				sendEmail: false,
				isDraggedFile: false,
				selectedTag: null,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			tags() {
				return this.communityStore.tags
			},
			selfId() {
				return this.communityStore.self.community_member_id
			},
			channelNames() {
				return Object.values(this.communityStore.channels).map(channel => channel.name)
			},
			isOwner() {
				return this.communityStore.isOwner
			},
			user() {
				const user = this.communityStore.self?.data
				return {
					name: user?.full_name,
					src: user?.profile_image,
					color: user?.color,
				}
			},
			allMembers() {
				const members = []
				const rawMembers = this.communityStore.members
				for (const memberId in this.communityStore.members) {
					members.push({
						value: rawMembers[memberId].data.full_name,
						id: memberId,
						profile_image: rawMembers[memberId].data.profile_image,
						type: 'member',
					})
				}
				if (this.mentionGroup) members.push({ id: 'channel', value: 'channel' })

				return members
			},
			canSubmit() {
				const content = this.content || ''
				return (
					(content?.replaceAll('<p><br></p>', '').replaceAll('<p></p>', '').trim() || this.files.length || this.audioRecords.length > 0) &&
					!this.isFileUploading &&
					this.isAudioUploaded
				)
			},
			isFileUploading() {
				return this.files.filter(f => f.progress !== undefined).length
			},
			isAudioUploaded() {
				return this.audioRecords.reduce((val, r) => (val && 'options' in r ? r.options.uploaded : r.uploaded), true)
			},
			maxFilesAllowed() {
				return 5 - (this.files.length ?? 0)
			},
			previewUrl() {
				return [...this.contentUrls.keys()]
					.map(url => url)
					.filter(url => {
						const obj = this.contentUrls.get(url)
						return obj.preview
					})
					.map(url => ({
						...this.contentUrls.get(url),
						$key: url,
					}))
			},
			tagOptions() {
				const options = [{label: 'Select a Category', value: null}]

				Object.values(this.tags).map((tag) => {
					options.push({value: tag.tag_id, label: tag.name === 'forum' ? 'general' : tag.name})
				})

				return options
			}
		},
		mounted() {
			this.$nextTick(() => {
				if (this.loadedFiles) this.files = this.loadedFiles
			})

			this.addEditorClickListener()
		},
		methods: {
			updateTag() {
				this.selectedTag = this.selectedTag
			},

			addEditorClickListener() {
				document.addEventListener('click', this.editorClickOutsideCheck)
			},
			removeEditorClickListener() {
				document.removeEventListener('click', this.editorClickOutsideCheck)
			},
			editorClickOutsideCheck(event) {
				const hasContent = this.content && this.content !== this.$constants.TIPTAP_EMPTY_MESSAGE
				const hasAttachments = this.audioRecords.length || this.files.length || this.previewUrl.length > 0 || hasContent
				const clickedOutsideOfEditor = !this.$refs?.messageFormeditor || !this.$refs.messageFormeditor?.contains(event.target)
				const clickedSendMessageButton = this.$refs?.sendMessage && this.$refs.sendMessage?.$el.contains(event.target)

				// Ignore clicks to send message
				if (clickedSendMessageButton) {
					return
				}

				if (!hasAttachments && clickedOutsideOfEditor) {
					this.$emit('blur')
				} else {
					this.$emit('focus')
				}
			},
			editorBlur() {
				this.$emit('blur')
			},
			async suggestMember(searchTerm) {
				return this.allMembers.filter(member => member.value.toLowerCase().includes(searchTerm.toLowerCase()))
			},
			isFileImage(file) {
				return !!file.type?.startsWith('image')
			},
			digitalAssetType(file) {
				if (file.type?.startsWith('image')) {
					return 'image'
				}

				if (file.type?.startsWith('video')) {
					return 'video'
				}

				return 'file'
			},
			digitalAssetTypeName(file) {
				if (file.type?.split('/')[1]) {
					return file.type?.split('/')[1]
				}
				return 'File'
			},
			removePreviewUrl(url) {
				const preview = this.contentUrls.get(url)
				if (typeof preview === 'undefined') {
					return
				}
				preview.preview = false
				this.contentUrls.set(url, preview)
			},
			onAudioStartPlaying(index, ev) {
				if (ev) {
					for (let i = 0; i < this.audioRecords.length; i += 1) {
						if (i !== index) {
							if (!('stop' in this.audioRecords[i])) {
								this.audioRecords[i].stop = 0
							}
							this.audioRecords[i].stop += 1
						}
					}
				}
			},
			onAudioReady(record) {
				this.audioRecords.push({
					...record,
					options: { progress: 0, uploaded: false, error: null },
				})
			},
			onAudioFileProgress(data) {
				const idx = this.audioRecords.findIndex(f => f.id === data.id)
				if (idx >= 0) {
					this.audioRecords[idx].options.progress = data.progress
				}
			},
			onAudioFileUploaded(data) {
				const idx = this.audioRecords.findIndex(f => f.id === data.id)
				if (idx >= 0) {
					this.audioRecords[idx].options.uploaded = true
					this.audioRecords[idx].src = data.src
				}
			},
			onAudioFileError(data) {
				this.$notify({ type: 'error', text: data.error })
				const idx = this.audioRecords.findIndex(f => f.id === data.id)
				if (idx >= 0) {
					this.audioRecords.splice(idx, 1)
				}
			},
			onRemoveAudioRecord(index) {
				this.audioRecords.splice(index, 1)
			},
			removeFile(fileId) {
				const fileIndex = this.files.findIndex(f => f.id === fileId)
				if (this.files[fileIndex].progress !== undefined) {
					// TODO: Stop file from uploading
					this.files.splice(fileIndex, 1)
				} else {
					this.files.splice(fileIndex, 1)
				}
				this.sendUpdateEvent()
			},
			pickFile(event) {
				this.$refs.hiddenFileUploadMessage.onPickFile()
				event.preventDefault()
			},
			sendMessage() {
				if (this.canSubmit) {
					let contentToSend = `${this.content}`
					let mentionsToSend = extractMentions(contentToSend)

					const files = this.files
					const linkPreviews = this.previewUrl.map(lp => lp.id)
					const audio = this.audioRecords.map(a => ({
						id: a.id,
						url: a.src,
						name: `${a.name}.wav`,
						type: a.blob.type,
					}))

					this.$emit(
						'submit',
						{
							content: contentToSend,
							files,
							audio,
							linkPreviews,
							mentions: mentionsToSend,
							notify: this.sendEmail ? this.$constants.MESSAGE_NOTIFY.IMMEDIATE : this.$constants.MESSAGE_NOTIFY.DEFAULT,
						},
						this.selectedTag
					)
					this.reset()
				}
				this.sendUpdateEvent()
			},
			reset() {
				this.content = ''
				this.files = []
				this.contentUrls.clear()
				this.audioRecords = []
				this.$refs.audioRecorderRef?.clearRecords()
				this.selectedTag = null
			},
			handleDragOver(e) {
				this.isDraggedFile = true
				e.preventDefault()
			},
			handleDropOver(e) {
				e.preventDefault()
				this.isDraggedFile = false
				this.$refs.hiddenFileUploadMessage.onFilePicked(e)
			},
			fileDrop(e) {
				this.$refs.hiddenFileUploadMessage.onFilePicked(e)
			},
			fileUploaded(e) {
				const fileIndex = this.files.findIndex(f => f.id === e.metadata.id)
				if (fileIndex !== -1) {
					this.files[fileIndex] = {
						url: e.src,
						name: e.name,
						id: e.metadata.id,
						type: e.type,
					}
				}
				this.sendUpdateEvent()
			},
			errorFileUploaded(e) {
				this.$notify({ type: 'error', text: e.error })
				if (e.metadata?.id) {
					this.files.splice(
						this.files.indexOf(f => f.id === e.metadata?.id),
						1
					)
				}
			},
			filePicked(files) {
				for (const file in files) {
					this.files.push({
						name: files[file].name,
						type: files[file].type,
						id: files[file].metadata.id,
						progress: 0,
					})
				}
			},
			progressUploaded(file) {
				this.files.forEach(f => {
					if (f.id === file.metadata.id) {
						f.progress = file.progress
					}
				})
			},
			cancel() {
				this.reset()
				this.$emit('cancel')
			},
			sendUpdateEvent() {
				this.$emit('messageUpdated', { content: this.content, files: this.files })
			},
			async getPreviewUrl(url) {
				const resp = await axios.post(`v1/communities/${this._current_community_slug}/preview/link`, { url })
				if (!resp.data?.ok) {
					return
				}
				this.contentUrls.set(url, {
					...resp.data,
					preview: true,
				})
			},
			getUrlFromContent(content) {
				if (DISABLE_LINK_PREVIEW) {
					return
				}
				content = content || ''
				if (this.contentUrlsTimeout !== null) {
					clearTimeout(this.contentUrlsTimeout)
				}
				this.contentUrlsTimeout = setTimeout(() => {
					const exp = /https?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]/gi
					const urlMatches = content?.match(exp) || null
					if (urlMatches !== null) {
						for (let i = 0, total = urlMatches.length; i < total; i += 1) {
							const url = urlMatches[i]
							if (!this.contentUrls.has(url)) {
								this.contentUrls.set(url, {
									preview: false,
								})
								this.getPreviewUrl(url)
							}
						}
						const contentUrlsKeys = [...this.contentUrls.keys()]
						for (let i = 0, total = contentUrlsKeys.length; i < total; i += 1) {
							if (!urlMatches.includes(contentUrlsKeys[i])) {
								this.contentUrls.delete(contentUrlsKeys[i])
							}
						}
					} else {
						this.contentUrls.clear()
					}
				}, 500)
			},
		},
		watch: {
			content(newVal) {
				this.sendUpdateEvent()
				this.getUrlFromContent(newVal)
			},
			focus: {
				immediate: true,
				handler(shouldFocus) {
					if (shouldFocus)
						this.$nextTick(() => {
							this.$refs.editor?.focus()
						})
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.chat-message-section {
		max-height: 50%;
		@apply p-1;

		&.disable {
			opacity: 0.7;
			pointer-events: none;
			user-select: none;
		}

		.chat-message-form {
			@apply flex flex-col h-full bg-white rounded-xl;

			// min-height: 120px;
			.chat-message-attachment {
				@apply flex gap-4 pl-14 ml-1 pr-4 py-3 flex-wrap;

				.attachment {
					@apply w-14 h-14 aspect-square relative bg-white border-stan-gray-strike border-2 rounded-xl;

					.img {
						@apply flex items-center;

						:deep(svg) {
							@apply bg-community-light w-8 h-8 aspect-square rounded p-1;

							rect,
							path {
								@apply fill-community-primary-d-text-color;
							}
						}
					}

					&.audio {
						@apply w-72 border-none;
						:deep(.audio-player-card) {
							@apply justify-between w-full;
						}
					}

					.loading-icon {
						@apply w-full h-full;
					}

					&.fileUploading {
						.img {
							@apply hidden;
						}

						.loading-icon {
							@apply block;
						}
					}

					&.fileUploaded {
						@apply h-full;
						.img {
							@apply block;
						}

						.loading-icon {
							@apply hidden;
						}
					}

					.action-item {
						@apply absolute -right-2 -top-2 rounded-full bg-stan-gray-primary;

						svg {
							@apply w-5 h-5 p-0.5;
						}
					}

					&.image {
						@apply bg-stan-gray-strike;

						:deep(.img) {
							@apply w-full h-full rounded-xl overflow-hidden;

							img {
								@apply border-0 bg-transparent;
							}
						}
					}

					&.file,
					&.video {
						@apply w-auto aspect-auto max-w-52 flex gap-3 p-2;

						.file-details {
							@apply flex flex-col select-none;

							.file-name {
								@apply truncate w-36 font-semibold;
							}

							.file-type {
								@apply text-stan-text-light font-light;
							}
						}
					}
				}
			}

			.chat-message-actions {
				display: flex;
				align-items: center;
				gap: 0.75rem;

				:deep(.app-emoji-wrapper) {
					.emoji-icon-button {
						padding: 0;
						height: 24px;

						svg {
							width: 20px;
							height: 20px;

							path,
							rect {
								fill: var(--community-primary-d-text-color);
							}
						}
					}
				}

				.interaction-button {
					height: 24px;
					padding: 0 1px;
				}

				.action-button {
					height: 38px;
				}
			}

			:deep(.content-wrapper) {
				@apply flex w-full gap-3 py-4 px-5 lg:py-2 lg:px-3 overflow-auto box-border max-h-[80vh] transition-all duration-500 ease-in-out;
				max-width: calc(100% - 2.5rem);

				.editor {
					@apply h-full grow box-border;
					border: none;
					display: flex;

					.ql-tooltip {
						z-index: 1;
					}

					.tiptap {
						@apply w-full p-0 box-border;

						&::before {
							@apply font-medium not-italic left-0 text-stan-gray-dark;
						}

						* {
							@apply text-stan-text-dark;
							font-family: Inter, sans;
							font-size: 1rem;
							line-height: 1.375rem;
						}
						.mention {
							@apply text-community-primary;
							box-decoration-break: clone;

							padding: 0.1rem 0.3rem;
						}
						.current-user-mention {
							@apply text-community-strike p-1;
						}

						a {
							@apply underline;
						}

						code {
							font-size: 0.875em;
						}

						pre.ql-syntax {
							background-color: var(--stan-oxford-blue-1);
							color: var(--stan-white);
							padding: 8px 16px;
							border-radius: 5px;
						}
					}

					a {
						color: var(--stan-primary-primary-color);
					}

					blockquote {
						border-left: 4px solid var(--stan-primary-soft-color);
						padding-left: 1rem;
						margin-top: 4px;
						margin-bottom: 4px;

						& + blockquote {
							margin-top: -4px;
						}
					}
				}
			}

			.send-wrapper {
				@apply w-full transition-all duration-500 ease-in-out h-0 overflow-hidden;

				.create-mode-buttons {
					@apply flex justify-between items-center w-full px-3 pb-3 box-border;

					.email-checkbox {
						gap: 10px;
						@apply flex mt-1 flex-row justify-start hover:cursor-pointer select-none;
					}

					.action-button {
						@media (max-width: 1023px) {
							@apply w-full;
						}
					}

					@media (max-width: 1023px) {
						@apply gap-y-4 items-start;
					}
				}

				.edit-mode-buttons {
					display: flex;
					gap: 10px;
					padding-right: 20px;
				}
			}
		}

		&.visible {
			@media (max-width: 639px) {
				width: calc(100% + 32px);
				@apply -m-4;
			}
			:deep(.content-wrapper) {
				@apply py-4 px-5;
			}

			:deep(#toolbar-container) {
				@apply h-10 px-2 mt-0 border-b;
			}

			.send-wrapper {
				@apply h-auto mt-0 overflow-visible;
			}
		}
	}

	.on-drop {
		opacity: 1;
		position: relative;
	}

	.drag-n-drop-section {
		@apply absolute top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center text-white font-bold;
	}

	.editor {
		@include para-1;
		@apply p-2 rounded-lg overflow-x-hidden overflow-y-scroll max-h-40;
		:deep(.tiptap) {
			p {
				&.is-editor-empty {
					&:first-child {
						&::before {
							color: #adb5bd;
							content: attr(data-placeholder);
							float: left;
							height: 0;
							pointer-events: none;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.ql-mention-list-container {
		width: 270px;
		padding: 12px;
		border: 1px solid #f0f0f0;
		border-radius: 4px;
		background: var(--stan-white, #fff);
		box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
		z-index: 9001;
		overflow: auto;
		max-height: 200px;
		flex-shrink: 0;

		scrollbar-width: auto;
		scrollbar-color: var(--stan-gray-strike-color);

		&::-webkit-scrollbar-track {
			background: #ffffff;
		}

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--stan-gray-strike-color);
			border-radius: 10px;
			border: 3px solid #ffffff;
		}

		.ql-mention-list {
			padding: 0;

			&-item {
				display: flex;
				padding: 10px 12px;
				align-items: center;
				gap: 10px;
				align-self: stretch;
			}
		}
	}

	.ql-mention-loading {
		line-height: 44px;
		padding: 0 20px;
		vertical-align: middle;
		font-size: 16px;
	}

	.ql-mention-list {
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	.ql-mention-list-item {
		cursor: pointer;
		color: var(--stan-text-dark-color, #131f60);
		/* p2/Regular */
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		/* 128.571% */
		letter-spacing: -0.28px;

		.mention-user-item {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;

			img {
				width: 18px;
				height: 18px;
				object-fit: contain;
			}

			.app-user-image {
				width: 18px;
				height: 18px;
				font-size: 8px !important;
			}
		}
	}

	.ql-mention-list-item.disabled {
		cursor: auto;
	}

	.ql-mention-list-item.selected {
		background: var(--stan-primary-light-color, #ebebff);
		text-decoration: none;
		border-radius: 3px;
	}
	.current-user-mention {
		@apply text-community-strike p-1;

		&:hover {
			background: var(--stan-gray-strike-color, #f2f4f8);
			transition: 0.1s ease;
		}
	}

	.mention {
		@apply text-community-primary p-1;

		&:hover {
			background: var(--stan-gray-strike-color, #f2f4f8);
			transition: 0.1s ease;
		}

		&[data-id='channel'] {
			background: var(--primary-light, #ebebff);
			color: var(--stan-primary-primary-color, #6355ff);
		}
	}
</style>
