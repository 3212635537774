import { defineStore } from 'pinia'
import axios from 'axios'
import Cookies from 'js-cookie'

export const useAuthStore = defineStore('auth', {
	state: () => ({
		_token: Cookies.get('community_token'),
		_user: null,
		_fan: null,
		_user_fans: [],
	}),
	getters: {
		user: state => state._user,
		fan: state => state._fan,
		token: state => state._token,
		user_fans: state => state._user_fans,
		user_fan_ids: state => Object.keys(state._user_fans),
	},
	actions: {
		loginWithEmailPassword(payload) {
			const communitySlug = payload?.communitySlug
			return new Promise((resolve, reject) => {
				axios
					.post('v1/authentication/complex/login', payload, { baseURL: this.$authApiUrl })
					.then(response => {
						this._token = response.data.token
						const expires = new Date()
						expires.setDate(expires.getDate() + 30)
						Cookies.set('community_token', response.data.token, {
							expires,
							path: '/',
						})
						if (communitySlug) {
							// Verify access to given community
							this.checkCommunityAccess(communitySlug)
								.then(() => {
									this.getUserDetails(communitySlug)
										.then(() => {
											resolve()
										})
										.catch(error => {
											throw error
										})
								})
								.catch(error => {
									reject(error)
								})
						} else {
							resolve()
						}
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		loginWithToken(payload) {
			const communitySlug = payload.communitySlug
			return new Promise((resolve, reject) => {
				axios
					.post(
						'v1/authentication/complex/token_login',
						{},
						{
							baseURL: this.$authApiUrl,
							headers: {
								Authorization: `Bearer ${payload.token}`,
							},
						}
					)
					.then(response => {
						this._token = response.data.token
						const expires = new Date()
						expires.setDate(expires.getDate() + 30)
						Cookies.set('community_token', response.data.token, { expires, path: '/' })

						this.checkCommunityAccess(communitySlug)
							.then(() => {
								this.getUserDetails(communitySlug)
									.then(() => {
										resolve()
									})
									.catch(error => {
										throw error
									})
							})
							.catch(error => {
								reject(error)
							})
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		getUserDetails() {
			return new Promise((resolve, reject) => {
				axios
					.get('v1/authentication/complex/get-details', {
						baseURL: this.$authApiUrl,
					})
					.then(response => {
						this._user = response.data.user
						this._fan = response.data.fan
						this._user_fans = response.data.user_fans
						const expires = new Date()
						expires.setDate(expires.getDate() + 30)
						Cookies.set('community_token', response.data.token, { expires, path: '/' })
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		updatePassword(payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('v1/authentication/fan/update-password', payload, {
						baseURL: this.$authApiUrl,
					})
					.then(() => {
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		logout(clear_root_token = false) {
			this._user = null
			this._token = null
			this._fan = null
			this._user_fans = []
			Cookies.remove('community_token')
			if (clear_root_token) Cookies.remove('token')
		},
		checkCommunityAccess(communitySlug) {
			// If not a 401 we have access to this community
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${communitySlug}/authentication`)
					.then(response => {
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
})
